<template>
  <span>Romajazzfestivalauctions</span>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'Romajazzfestivalauctions',
  data() {
    return {
      get,
    };
  },
  methods: {
    ...mapActions(['getMe']),
    ...mapMutations(['appReady', 'redirectToIP', 'setLoginInfo']),
    sendToRegistration() {
      this.redirectToIP({
        router: this.$router,
        registered: true,
        event: 'romajazzfestivalauctions',
      });
    },
    handleStatus() {
      if (!get(this, 'me.registered')) {
        this.sendToRegistration();
      } else {
        const jwt = get(this, 'me.jwt');
        const url = `${get(this.conf, 'adamanticBase')}/auctions?token=${jwt}`;
        window.location = url;
      }
    }
  },
  computed: {
    ...mapState([
      'me',
      'conf',
    ]),
  },
  mounted() {
    let status = this.$cookies.get(`${window.TOK_NS}storageAccessData`);
    if (!status || status == null) {
      status = this.$ls.get('storageAccessData', false);
    }
    if (status && status != null) {
      this.setLoginInfo(status);
      if (!this.me) {
        this.getMe()
          .then(() => {
            this.handleStatus()
          })
          .catch((error) => {
            window.app.$log.error(error);
          });
      } else {
        this.handleStatus()
      }
    } else {
      this.sendToRegistration();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
